<template>
    <div class="monitoringIndicators" v-loading="loading">
        <header>
            <el-date-picker
                v-model="time"
                align="right"
                type="month"
                value-format="yyyy-MM"
                placeholder="选择年月"
                :clearable="false"
            >
            </el-date-picker>
        </header>
        <h4 class="categoryName">
            经营类指标
        </h4>
        <div class="businessClass">
            <div class="timelinessRate">
                <div class="title">
                    订单交付及时率
                </div>
                <div class="proportion">
                    <div class="identification">
                        <i class="iconfont iconshujutubiaobingtu-"></i>
                    </div>
                    <p>{{ timelinessRateData.TotalTimelinessRate || 0 }}%</p>
                </div>
                <div id="timelinessRateChart" class="charts"></div>
            </div>
            <div class="perCapitaOutput">
                <ul>
                    <li @click="plannedCompletionRate">
                        <div class="class">
                            <div class="identification">
                                <i class="iconfont iconhuanxingtu"></i>
                            </div>
                            <h5>销售计划完成率</h5>
                        </div>
                        <div>
                            <p>{{ salesCompletionRateData.centage || 0 }}</p>
                            <span>%</span>
                        </div>
                    </li>
                    <li @click="unitCost">
                        <div class="class">
                            <div class="identification">
                                <i class="iconfont iconqian"></i>
                            </div>
                            <h5>单位成本</h5>
                        </div>
                        <div>
                            <p>{{ unitCostData.centage || 0 }}</p>
                            <span>元/m³</span>
                        </div>
                    </li>
                </ul>
                <div id="salesCompletionRateChart" class="charts"></div>
            </div>
            <div class="customerSatisfaction">
                <div class="score">
                    <div class="identification">
                        <i class="iconfont iconjiaoshimanyidu"></i>
                    </div>
                    <div class="star">
                        <p>顾客满意度</p>
                        <el-rate v-model="score" show-text disabled></el-rate>
                    </div>
                </div>
                <div id="humanColumnDiagram" class="charts"></div>
                <div class="satisfiedNumber">
                    <div>
                        <h3>满意率</h3>
                        <p>{{ customerSatisfactionData.satisfied_rate || 0 }}%</p>
                    </div>
                    <div>
                        <i class="iconfont iconrenshu1"></i>
                        <h5>{{ customerSatisfactionData.people_total || 0 }}</h5>
                        <p>总人数</p>
                    </div>
                    <div>
                        <i class="iconfont iconjiaoshimanyidu"></i>
                        <h5>{{ customerSatisfactionData.satisfied_total || 0 }}</h5>
                        <p>满意人数</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="monitoringData">
            <div class="qualityMonitoring">
                <h4 class="categoryName">
                    技术质量监测
                </h4>
                <div>
                    <div class="conformityMonitoring">
                        <div class="title optional" @click="firstClassInspection">
                            原材料一等品检验合格率
                        </div>
                        <div class="proportion" @click="firstClassInspection">
                            <div class="identification">
                                <i class="iconfont iconhuanxingtu"></i>
                            </div>
                            <p>{{ conformityMonitoringData.centage || 0 }}%</p>
                        </div>
                        <div id="conformityMonitoringChart" class="charts"></div>
                    </div>
                    <div class="monthlyMonitoring">
                        <div class="title optional" @click="strengthTest">
                            28天强度（115）检验合格率
                        </div>
                        <div class="proportion" @click="strengthTest">
                            <div class="identification">
                                <i class="iconfont iconbaifenbi"></i>
                            </div>
                            <p>{{ strengthVerificationData.centage || 0 }}%</p>
                        </div>
                        <div id="monthlyMonitoringChart" class="charts"></div>
                    </div>
                </div>
            </div>
            <div class="vehicle">
                <div class="vehicleMonitoring">
                    <h4 class="categoryName">
                        车辆监测
                    </h4>
                    <div class="vehicleDetectionRate" @click="vehicleMonitoring">
                        <div class="title">
                            运输车辆监管率
                        </div>
                        <div class="proportion">
                            <div class="identification">
                                <i class="iconfont iconcheliang"></i>
                            </div>
                            <p>{{ vehicleSupervisionRateData.centage || 0 }}%</p>
                        </div>
                    </div>
                </div>
                <div class="energyConsumptionMonitoring">
                    <h4 class="categoryName">
                        能耗监测
                    </h4>
                    <div class="comprehensiveEnergyConsumption" @click="openMonitoringIndicators">
                        <div class="title">
                            单位综合能耗
                        </div>
                        <div class="proportion">
                            <div class="identification">
                                <i class="iconfont iconnenghao"></i>
                            </div>
                            <p>{{ comprehensiveEnergyConsumptionData || 0 }}</p>
                            <span>kgce/m³</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
import { drawLine } from '@/assets/js/charts';

export default {
    name: 'monitoring-indicators',
    components: {},
    props: {},
    data() {
        return {
            loading: false,
            time: '',
            year: '',
            // 用户权限
            isEdit: false,
            // 订单交付及时率
            timelinessRateData: {},
            // 销售计划完成率
            salesCompletionRateData: {},
            // 单位成本
            unitCostData: {},
            // 顾客满意度
            customerSatisfactionData: {},
            // 评分
            score: 0,
            // 原材料一等品检验合格率
            conformityMonitoringData: {},
            // 28天强度（115）检验合格率
            strengthVerificationData: {},
            // 运输车辆监管率
            vehicleSupervisionRateData: {},
            // 单位综合能耗
            comprehensiveEnergyConsumptionData: {},

        };
    },
    computed: {},
    watch: {
        async time(newVal) {
            this.loading = true;

            if (!newVal) {
                this.$message({
                    message: '请选择时间',
                    type: 'warning',
                });
                this.loading = false;
                return;
            }

            const newTime = newVal.split('-');
            this.year = newTime[0];

            // 订单交付及时率
            this.timelinessRateData = await this.getTimelinessRateData() || {};
            this.timelinessRateChart(this.timelinessRateData.list);

            // 销售计划完成率
            this.salesCompletionRateData = await this.getSalesCompletionRateData() || {};
            this.salesCompletionRateChart(this.salesCompletionRateData.list);

            // 单位成本
            this.unitCostData = await this.getUnitCostData() || {};

            // 原材料一等品检验合格率
            this.conformityMonitoringData = await this.getConformityMonitoringData() || {};
            this.conformityMonitoringChart(this.conformityMonitoringData.list);

            // 28天强度（115）检验合格率
            this.strengthVerificationData = await this.getStrengthVerificationData();
            this.monthlyMonitoringChart(this.strengthVerificationData.list);

            // 运输车辆监管率
            this.vehicleSupervisionRateData = await this.getVehicleSupervisionRateData();

            // 单位综合能耗
            this.comprehensiveEnergyConsumptionData = await this.getComprehensiveEnergyConsumptionData();

            this.loading = false;
        },
    },
    created() {},
    mounted() {
        this.time = util.timeFormat(new Date(new Date()), 'yyyy-MM');
        this.init();
    },
    destroyed() {},
    methods: {
        async init() {
            this.loading = true;

            this.customerSatisfactionData = await this.getCustomerSatisfactionData();
            this.customerSatisfactionChart(this.customerSatisfactionData);

            this.isEdit = this.editPermissions();

            this.loading = false;
        },

        // 用户权限
        editPermissions() {
            const RoleCodes = this.$takeTokenParameters('RoleCodes') || '';
            if (RoleCodes.indexOf('J10-01') !== -1
                || RoleCodes.indexOf('J70-01') !== -1
                || RoleCodes.indexOf('J90-01') !== -1
            ) {
                return true;
            }
            return false;
        },

        // 加载订单交付及时率
        timelinessRateChart(chartList) {
            const chartData = {
                borderColor: 'rgba(0, 0, 0, 0.1)',
                fontColor: 'rgba(151, 158, 167, 1)',
                xdata: [],
                grid: {
                    left: 40,
                    top: 30,
                    right: 20,
                    bottom: 30,
                },
                dataZoom: [],
                yAxisName: ' ',
                ydata: [{
                    color: ['rgba(1, 200, 254, 1)', 'rgba(1, 200, 254, 0.16)', 'rgba(7, 109, 253, 0)'],
                    data: [],
                }],
            };
            if ((chartList || []).length > 7) {
                chartData.dataZoom.push({
                    show: true,
                    start: 85,
                    end: 100,
                });
                chartData.grid.bottom = 70;
            }
            (chartList || []).forEach(item => {
                chartData.xdata.push(item.timelyDate);
                chartData.ydata[0].data.push(item.timelyRate);
            });
            setTimeout(() => {
                drawLine('timelinessRateChart', chartData);
            }, 500);
        },

        // 加载销售计划完成率
        salesCompletionRateChart(chartList) {
            const chartData = {
                borderColor: 'rgba(0, 0, 0, 0.1)',
                fontColor: 'rgba(151, 158, 167, 1)',
                xdata: [],
                grid: {
                    left: 50,
                    top: 40,
                    right: 20,
                    bottom: 30,
                },
                yAxisName: '%',
                ydata: [
                    {
                        color: ['rgba(5, 222, 191, 1)', 'rgba(5, 222, 191, 0.2)', 'rgba(4, 162, 220, 0)'],
                        data: [],
                    },
                ],
            };
            (chartList || []).forEach(item => {
                chartData.xdata.push(item.date);
                chartData.ydata[0].data.push(item.centage);
            });
            setTimeout(() => {
                drawLine('salesCompletionRateChart', chartData);
            }, 500);
        },

        // 销售计划完成率调整
        plannedCompletionRate() {
            this.$toast({
                title: true,
                text: '销售计划完成率',
                type: 'eject',
                width: '80%',
                t_url: 'tableList/index',
                viewPosition: 'view',
                extr: {
                    code: {
                        TableCode: 'jc_saleplan',
                    },
                    closeCallback: this.addplannedCompletionRateCallBack,
                },
            });
        },
        // 销售计划完成率调整回调
        async addplannedCompletionRateCallBack() {
            this.salesCompletionRateData = await this.getSalesCompletionRateData() || {};
            this.salesCompletionRateChart(this.salesCompletionRateData.list);
        },

        // 单位成本调整
        unitCost() {
            this.$toast({
                title: true,
                text: '单位成本模块增加',
                type: 'eject',
                width: '80%',
                t_url: 'tableList/index',
                viewPosition: 'view',
                extr: {
                    code: {
                        TableCode: 'jc_unitcost',
                    },
                    closeCallback: this.addunitCostCallBack,
                },
            });
        },
        // 单位成本调整回调
        async addunitCostCallBack() {
            this.unitCostData = await this.getUnitCostData() || {};
        },

        // 加载顾客满意度
        customerSatisfactionChart(data) {
            this.score = Math.round(data.satisfied_rate / 100 * 5);
            setTimeout(() => {
                this.drawLevelBar('humanColumnDiagram', data);
            }, 500);
        },

        // 加载原材料一等品检核合格率
        conformityMonitoringChart(chartList) {
            const chartData = {
                borderColor: 'rgba(0, 0, 0, 0.1)',
                fontColor: 'rgba(151, 158, 167, 1)',
                xdata: [],
                grid: {
                    left: 40,
                    top: 40,
                    right: 20,
                    bottom: 30,
                },
                yAxisName: '%',
                ydata: [
                    {
                        color: ['rgba(239, 171, 84, 1)', 'rgba(253, 210, 85, 0.16)', 'rgba(253, 116, 101, 0)'],
                        data: [],
                    },
                ],
            };
            (chartList || []).forEach(item => {
                chartData.xdata.push(item.date);
                chartData.ydata[0].data.push(item.centage);
            });
            setTimeout(() => {
                drawLine('conformityMonitoringChart', chartData);
            }, 500);
        },

        // 原材料一等品检核合格率添加
        firstClassInspection() {
            this.$toast({
                title: true,
                text: '原材料检核合格率',
                type: 'eject',
                width: '80%',
                t_url: 'tableList/index',
                viewPosition: 'view',
                extr: {
                    code: {
                        TableCode: 'jc_rawcheck',
                    },
                    closeCallback: this.addfirstClassInspectionCallBack,
                },
            });
        },
        // 原材料一等品检核合格率添加回调
        async addfirstClassInspectionCallBack() {
            this.conformityMonitoringData = await this.getConformityMonitoringData() || {};
            this.conformityMonitoringChart(this.conformityMonitoringData.list);
        },

        // 加载28天强度（115）检验合格率
        monthlyMonitoringChart(chartList) {
            const chartData = {
                borderColor: 'rgba(0, 0, 0, 0.1)',
                fontColor: 'rgba(151, 158, 167, 1)',
                xdata: [],
                legendData: [],
                grid: {
                    left: 40,
                    top: 40,
                    right: 20,
                    bottom: 30,
                },
                yAxisName: '%',
                ydata: [
                    {
                        color: ['rgba(94, 200, 117, 1)', 'rgba(94, 200, 117, 0.16)', 'rgba(213, 201, 64, 0)'],
                        data: [],
                    },
                ],
            };
            (chartList || []).forEach(item => {
                chartData.xdata.push(item.date);
                chartData.ydata[0].data.push(item.centage);
            });
            setTimeout(() => {
                drawLine('monthlyMonitoringChart', chartData);
            }, 500);
        },

        // 28天强度（115）检验合格率新增
        strengthTest() {
            this.$toast({
                title: true,
                text: '28天强度监测合格率',
                type: 'eject', width: '15.4rem',
                t_url: 'tableList/index',
                viewPosition: 'view',
                extr: {
                    code: {
                        TableCode: 'jc_monitorqualified',
                    },
                    closeCallback: this.addstrengthTestCallBack,
                },
            });
        },
        // 28天强度（115）检验合格率新增回调
        async addstrengthTestCallBack() {
            this.strengthVerificationData = await this.getStrengthVerificationData();
            this.monthlyMonitoringChart(this.strengthVerificationData.list);
        },

        // 运输车辆监管率新增
        vehicleMonitoring() {
            this.$toast({
                title: true,
                text: '运输车辆监管率',
                type: 'eject',
                width: '80%',
                t_url: 'tableList/index',
                viewPosition: 'view',
                extr: {
                    code: {
                        TableCode: 'jc_transportcar',
                    },
                    closeCallback: this.addvehicleMonitoringCallBack,
                },
            });
        },
        // 运输车辆监管率新增回调
        async addvehicleMonitoringCallBack() {
            this.vehicleSupervisionRateData = await this.getVehicleSupervisionRateData();
        },

        // 单位综合能耗打开监测指标页面
        openMonitoringIndicators() {
            // if (this.isEdit === false) {return;}
            this.$toast({
                title: true,
                type: 'eject',
                width: '80%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'station_energy_consumption_list',
                    },
                    closeCallback: this.addCompleteCallBack,
                },
            });
        },

        // 单位综合能耗添加回调
        async addCompleteCallBack() {
            this.comprehensiveEnergyConsumptionData = await this.getComprehensiveEnergyConsumptionData();
        },

        // 满意度人形柱图
        drawLevelBar(obj, data) {
            const myCharts = window.$globalHub.$echarts.init(document.getElementById(obj));
            const spirit = 'image://' + require('@/assets/images/echarts/1.png');
            const spirit_jd = 'image://' + require('@/assets/images/echarts/2.png');
            const maxData = data.people_total || 100;
            const option = {
                tooltip: {
                },
                xAxis: {
                    max: maxData,
                    splitLine: { show: false },
                    offset: 10,
                    axisLine: {
                        lineStyle: {
                            color: '#999',
                        },
                    },
                    axisLabel: {
                        margin: 10,
                    },
                },
                yAxis: {
                    data: [],
                    inverse: true,
                    axisTick: { show: false },
                    axisLine: { show: false },
                    axisLabel: {
                        margin: 10,
                        color: '#999',
                        fontSize: 16,
                    },
                },
                grid: {
                    top: 10,
                    left: 20,
                    right: 20,
                    bottom: 10,
                },
                series: [{
                    type: 'pictorialBar',
                    symbol: spirit_jd,
                    symbolRepeat: 'fixed',
                    symbolMargin: '0',
                    symbolClip: true,
                    symbolSize: 68,
                    symbolBoundingData: maxData,
                    data: [data.satisfied_total || 0],
                    z: 10,
                }, {
                    type: 'pictorialBar',
                    label: {
                        show: false,
                    },
                    animationDuration: 0,
                    symbolRepeat: 'fixed',
                    symbolMargin: '0',
                    symbol: spirit,
                    symbolSize: 68,
                    symbolBoundingData: maxData,
                    data: [data.satisfied_total || 0],
                    z: 5,
                }],
            };
            myCharts.setOption(option);
            window.addEventListener('resize', function () {
                myCharts.resize();
            });
        },

        // 订单交付及时率
        getTimelinessRateData() {
            const path = `/interfaceApi/report/monitoring/indicators/order_timely_delivery?month=${this.time}`;
            return this.$axios.$get(path, { defEx: true });
        },

        // 销售计划完成率
        getSalesCompletionRateData() {
            const path = `/interfaceApi/report/monitoring/indicators/get_saleplan?date=${this.time}`;
            return this.$axios.$get(path, { defEx: true });
        },

        // 单位成本
        getUnitCostData() {
            const path = `/interfaceApi/report/monitoring/indicators/get_unitcost?date=${this.time}`;
            return this.$axios.$get(path, { defEx: true });
        },

        // 砼年产量
        getPerCapitaOutputData() {
            const path = `/interfaceApi/report/monitoring/indicators/concrete_annual_output?year=${this.year}`;
            return this.$axios.$get(path, { defEx: true });
        },

        // 获取当前租户人员信息id
        getTenantPersonnelID() {
            const path = '/interfaceApi/report/monitoring/indicators/get_personnel_maintenance_id';
            return this.$axios.$post(path, { defEx: true });
        },

        // 设置人数
        getsetUpPeople(people_total) {
            const path = `/interfaceApi/report/monitoring/indicators/save_personnel_maintenance?people_total=${people_total}`;
            return this.$axios.$post(path, { defEx: true });
        },

        // 顾客满意度
        getCustomerSatisfactionData() {
            const path = '/interfaceApi/report/monitoring/indicators/get_customer_satisfied';
            return this.$axios.$get(path, { defEx: true });
        },

        // 原材料一等品检验合格率
        getConformityMonitoringData() {
            const path = `/interfaceApi/report/monitoring/indicators/get_rawcheck?date=${this.time}`;
            return this.$axios.$get(path, { defEx: true });
        },

        // 28天强度（115）检验合格率
        getStrengthVerificationData() {
            const path = `/interfaceApi/report/monitoring/indicators/get_monitorqualified?date=${this.time}`;
            return this.$axios.$get(path, { defEx: true });
        },

        // 运输车辆监管率
        getVehicleSupervisionRateData() {
            const path = `/interfaceApi/report/monitoring/indicators/get_transportcar?date=${this.time}`;
            return this.$axios.$get(path, { defEx: true });
        },

        // 单位综合能耗
        getComprehensiveEnergyConsumptionData() {
            const path = `/interfaceApi/report/monitoring/indicators/get_station_energy_consumption?month=${this.time}`;
            return this.$axios.$get(path, { defEx: true });
        },
    },
};
</script>
<style lang="stylus" scoped>
.monitoringIndicators
    height 100%
    position relative
    background: #EEF3F8;
    padding: 0.2rem 0.2rem 0.05rem;
    header
        position absolute
        top 0.05rem
        right 0.2rem
        >>>.el-date-editor
            margin-left 0.1rem
            width 1.5rem !important
    >div
        height calc(50% - 0.26rem)
    .categoryName
        font-size 0.16rem
        line-height 0.16rem
        color #333
        margin-bottom 0.1rem
    .title
        font-size 0.24rem
        line-height 0.24rem
        color #333333
        margin 0.2rem 0 0.15rem 0.3rem
        &.optional
            cursor pointer
    .proportion
        display flex
        margin 0 0 0.2rem 0.3rem
        .identification
            width 0.56rem
            height 0.56rem
            border-radius 0.08rem
            text-align center
            i
                font-size 0.3rem
                color #fff
                line-height 0.56rem
        p
            font-size 0.44rem
            line-height 0.56rem
            margin-left 0.2rem
            font-weight 600
        span
            padding-left 0.1rem
            font-size 0.16rem
            color #666666
            line-height 0.7rem
    .charts
        width 100%
        height calc(100% - 1.5rem)
    .businessClass
        display flex
        justify-content space-between
        margin-bottom 0.2rem
        >div
            height 100%
            background #fff
        .timelinessRate
            width 36.5%
            .proportion
                .identification
                    background linear-gradient(44deg, #00C6FB 0%, #005BEA 100%)
                p
                    color #0091ff
        .perCapitaOutput
            width 39%
            ul
                display flex
                justify-content space-between
                padding 0.3rem 0.1rem 0.2rem 0.1rem
                li
                    width calc(100%/2 - 0.1rem)
                    height 1rem
                    border-radius 0.08rem
                    padding 0.15rem
                    margin 0 0.1rem
                    cursor pointer
                    .class
                        display flex
                        margin-bottom 0.1rem
                        h5
                            color #fff
                            font-size 0.18rem
                            line-height 0.3rem
                        .identification
                            width 0.3rem
                            height 0.3rem
                            background #fff
                            border-radius 50%
                            text-align center
                            margin-right 0.1rem
                            i
                                font-size 0.2rem
                                line-height 0.3rem
                    >div
                        display flex
                        p
                            font-size 0.36rem
                            line-height 0.36rem
                            color #fff
                            margin 0 0.05rem
                        span
                            color #fff
                            font-size 0.16rem
                            line-height 0.5rem
                    &:nth-of-type(1)
                        background linear-gradient(270deg, #6EA0FF 0%, #4181FF 100%)
                        .identification
                            i
                                color #4382FF
                    &:nth-of-type(2)
                        background linear-gradient(270deg, #FEA979 0%, #FE7F37 100%)
                        .identification
                            i
                                color #FE7F37
                    &:nth-of-type(3)
                        background linear-gradient(270deg, #7EE36B 0%, #2CB711 100%)
                        .identification
                            i
                                color #2EB813
        .customerSatisfaction
            width 23%
            display flex
            flex-direction column
            justify-content space-between
            padding-bottom 0.2rem
            .score
                padding 0.2rem
                display flex
                .identification
                    width 0.56rem
                    height 0.56rem
                    text-align center
                    border-radius 0.08rem
                    background linear-gradient(40deg, #FD9D00 0%, #FA6400 100%)
                    i
                        color #fff
                        font-size 0.3rem
                        line-height 0.56rem
                .star
                    margin-left 0.1rem
                    p
                        font-size 0.16rem
                        color #666666
                        line-height 0.24rem
                        margin-bottom 0.05rem
                    >>>.el-rate
                        .el-rate__item
                            .el-rate__icon
                                font-size 0.24rem !important
                        .el-rate__text
                            color #F78900 !important
                            font-size 0.16rem !important
            .charts
                width 100%
                height 0.8rem
            .satisfiedNumber
                height 1.24rem
                padding 0.2rem 0.2rem 0
                display flex
                justify-content space-between
                >div
                    height 100%
                    margin 0 0.05rem
                    padding 0.1rem
                    display flex
                    flex-wrap wrap
                    align-content space-between
                    i
                        font-size 0.16rem
                        width 100%
                    h5,p
                        width 100%
                    &:nth-of-type(1)
                        box-shadow 0px 0px 12px 0px rgba(250, 100, 0, 0.3)
                        h3
                            font-size 0.24rem
                            color #666666
                            width 100%
                        p
                            font-size 0.42rem
                            color #FA6400
                            font-weight 600
                            line-height 0.42rem
                    &:nth-of-type(2)
                        box-shadow 0px 0px 12px 0px rgba(0, 167, 131, 0.3)
                        i
                            color #00A783
                        h5
                            color #00A783
                            font-size 0.24rem
                        p
                            color #666666
                            white-space nowrap
                            font-size 0.14rem
                    &:nth-of-type(3)
                        box-shadow 0px 0px 12px 0px rgba(0, 145, 255, 0.3)
                        i
                            color #0091FF
                        h5
                            color #0091FF
                            font-size 0.24rem
                        p
                            color #666666
                            white-space nowrap
                            font-size 0.14rem
    .monitoringData
        display flex
        justify-content space-between
        .qualityMonitoring
            width 76%
            >div
                height calc(100% - 0.26rem)
                display flex
                justify-content space-between
                >div
                    background #fff
            .conformityMonitoring
                width 48%
                .proportion
                    .identification
                        background linear-gradient(243deg, #FA8C47 0%, #EFAB54 100%)
                    p
                        color #FF8331
            .monthlyMonitoring
                width 51%
                .proportion
                    .identification
                        background linear-gradient(40deg, #76E0A8 0%, #13BD63 100%)
                    p
                        color #5EC875
        .vehicle
            width 23%
            height 100%
            >div
                height calc(50% - 0.1rem)
            .vehicleMonitoring
                margin-bottom 0.2rem
            .vehicleMonitoring,
            .energyConsumptionMonitoring
                >div
                    overflow hidden
                    background #fff
                    height calc(100% - 0.26rem)
                    .proportion
                        .identification
                            border-radius 50%
            .vehicleDetectionRate
                cursor pointer
                .proportion
                    .identification
                        background linear-gradient(208deg, #3A96FE 0%, #40B6FF 100%)
                    p
                        color #0091FF
            .comprehensiveEnergyConsumption
                cursor pointer
                .proportion
                    .identification
                        background linear-gradient(45deg, #EFAB54 0%, #FA8C47 100%)
                    p
                        color #FF8331

</style>
